<template>
  <div class="watchlist" :dir="[localDisplayLang === 'ara' ? 'rtl' : 'ltr']">
    <div class="title">
      <div class="container py-0">
        <div class="iconTextWrapper">
          <img
          src="@/assets/icons/back.svg"
          v-if="localDisplayLang === 'eng'"
          alt
          @click="initialRoute"
        />
        <img
          src="@/assets/icons/back.svg"
          class="rotate-img"
          v-else
          @click="initialRoute"
          alt
        />
        &nbsp;&nbsp;&nbsp;
        <p style="margin: 0px 10px">{{ menuTitle }}</p>
        </div>
      </div>
    </div>

    <div v-if="isLoading">
      <div v-for="(item, index) in 2" :key="index">
        <categoriesPlaceholder></categoriesPlaceholder>
      </div>
    </div>
    <div>
      <div
        :key="finalPlaylistMovies.length || finalPlaylistSeries.length"
        v-if="
          finalPlaylistSeries.length === 0 &&
            finalPlaylistMovies.length === 0 &&
            !isLoading
        "
      >
        <p class="empty-message first-message">{{ mylistEmpty }}</p>
        <p class="empty-message">{{ mylistEmptySubTitle }}</p>

        <!-- <ErrorAlert :message="mylistEmpty"></ErrorAlert> -->
        <!-- <ErrorAlert :message="mylistEmptySubTitle"></ErrorAlert> -->
      </div>
      <div
        :key="finalPlaylistMovies.length"
        class="contents"
        v-if="finalPlaylistMovies.length > 0 && !isLoading"
      >
        <div class="container" style="position:relative">
          <h3 class="heading-watchlist">{{ $t("MOVIES") }}</h3>

          <div class="watchlist-grids">
            <watchlistGridCard
              :key="finalPlaylistMovies.length || localDisplayLang"
              :contents="finalPlaylistMovies"
              @removedWatchlistContent="checkWatchlistContent"
            ></watchlistGridCard>
          </div>
        </div>
      </div>

      <div
        :key="finalPlaylistSeries.length"
        class="contents"
        v-if="finalPlaylistSeries.length > 0 && !isLoading"
      >
        <div class="container" style="position:relative">
          <h3 class="heading-watchlist">{{ $t("SERIES") }}</h3>

          <div class="watchlist-grids">
            <watchlistGridCard
              :key="finalPlaylistSeries.length || localDisplayLang"
              :contents="finalPlaylistSeries"
              @removedWatchlistContent="checkWatchlistContent"
            ></watchlistGridCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
  },
  data() {
    return {
      menuTitle: null,
      watchContents: false,

      watchlistContent: [],
      categorizedMovieContent: [],
      categorisedTvshow: [],
      categories: [],
      filteredContents: [],
      playlist: [],
      menuDetailItem: null,
      localDisplayLang: null,
      mylistEmpty: "",
      mylistEmptySubTitle: "",
      detailPlaylist: [],
      finalPlaylist: [],
      totalContents: [],

      finalPlaylistSeries: [],
      finalPlaylistMovies: [],

      firebaseInstance: null,

      moviesReload: false,
      seriesReload: false,

      isLoading: false,
      noContentsInWatchlist: false,
    };
  },
  watch: {
    localDisplayLang(val) {
      if (val) {
        this.mylistEmpty = this.$t("Your watchlist is Empty");
        this.mylistEmptySubTitle = this.$t(
          "Add your favorite content that you want to watch later"
        );
      }
    },
    detailPlaylist(val) {
      if (val.length === this.playlist.length) {
        this.finalPlaylist = val.map((item, i) =>
          Object.assign({}, item, this.playlist[i])
        );
        this.finalPlaylist = this.finalPlaylist.sort((a, b) => {
          return b.updatedat - a.updatedat;
        });
        console.log("finalPlaylist--------->", this.finalPlaylist);
        this.watchContents = true;
      }
    },
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberid", "profileid"]),

    locale() {
      console.log("CHANGE IN LOCALE");
      return this.$i18n.locale;
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.mylistEmpty = this.$t("Your watchlist is Empty");
    this.mylistEmptySubTitle = this.$t(
      "Add your favorite content that you want to watch later"
    );

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;

      this.menuTitle = this.localDisplayLang === "ara" ? "نوربلاي" : "My List";

      console.log("Language changed", data);

      this.checkPlaylist();
    });

    this.menuTitle = this.localDisplayLang === "ara" ? "نوربلاي" : "My List";

    // eventBus.$on(`contents-for-watchlist`, (response) => {
    //   // console.log("content detail response", response);
    //   this.detailPlaylist.push(response);
    //   // console.log("detailPlaylist-------->", this.detailPlaylist);
    // });
  },

  mounted() {
    this.checkPlaylist();

    eventBus.$on("on-watchlist-state-change", () => {
      console.log("EVENT WATCHLIST FIRED");
      this.checkPlaylist();
    });
  },

  methods: {
    ...mapActions(["listBundleItem", "listContent"]),

    checkPlaylist() {
      this.isLoading = true;
      this.playlist = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      let path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

      this.firebaseInstance = firebase.database().ref(path);

      this.firebaseInstance.once("value").then((snapshot) => {
        let nodeContentArr = this.collectionsToArray(snapshot.val());

        let totalContents = [];

        nodeContentArr.forEach((element, index) => {
          if (element.inwatchlist) {
            totalContents.push(element);
            console.log("ONLY IN WATCHLIST -->", element.inwatchlist);
          }
        });

        console.log("THE FINAL PLAYLIST", totalContents);

        if (totalContents.length === 0) {
          // this.$router.push("/watchlist");
          this.finalPlaylistMovies = [];
          this.finalPlaylistSeries = [];

          this.isLoading = false;
          return;
        }

        this.totalContents = totalContents;

        if (this.totalContents && this.totalContents.length > 0) {
          console.log("THE BLOCK ENTERED -->");
          this.makeTheWatchlistAPIcall();
        } else {
          this.noContentsInWatchlist = true;
          this.isLoading = false;
        }
      });
    },

    makeTheWatchlistAPIcall() {
      this.moviesReload = false;
      this.seriesReload = false;

      let seriesObjectIdArr = [];
      let moviesObjectIdArr = [];

      this.totalContents.forEach((element, index) => {
        if (element.category === "MOVIE") {
          moviesObjectIdArr.push(`"${element.objectid}"`);
        } else if (element.category === "TVSHOW") {
          seriesObjectIdArr.push(`"${element.objectid}"`);
        }
      });

      this.finalPlaylistMovies = [];

      if (moviesObjectIdArr && moviesObjectIdArr.length > 0) {
        let payload = {
          objecttype: "CONTENT",
          contentlist: "[" + `${moviesObjectIdArr}` + "]",
          displaylanguage: this.localDisplayLang,
        };

        this.listContent(payload).then((data) => {
          this.finalPlaylistMovies = data.data.data;

          console.log("MOVIES API RESPONSE", this.finalPlaylistMovies);

          if (this.isLoading) {
            this.isLoading = false;
          }
        });
      } else {
        this.finalPlaylistMovies = [];
      }

      this.finalPlaylistSeries = [];

      if (seriesObjectIdArr && seriesObjectIdArr.length > 0) {
        let payload = {
          objecttype: "SERIES",
          contentlist: "[" + `${seriesObjectIdArr}` + "]",
          displaylanguage: this.localDisplayLang,
        };

        this.listContent(payload).then((data) => {
          this.finalPlaylistSeries = data.data.data;

          console.log("SERIES API RESPONSE", this.finalPlaylistSeries);

          if (this.isLoading) {
            this.isLoading = false;
          }
        });
      } else {
        this.finalPlaylistSeries = [];
      }
    },

    showEmptyMessage() {
      // this.watchContents = true;
      setTimeout(() => {
        this.setTimeout = false;
        this.watchlistContent = null;
      }, 4000);
    },

    checkWatchlistContent(value) {
      if (value) {
        console.log("THE VALUE", value);
        this.checkPlaylist();
      }
    },

    initialRoute() {
      this.$router.push({ name: "Home" });
    },
  },

  beforeDestroy() {
    console.log("DESTROY HOOK CALLED");

    eventBus.$off("on-watchlist-state-change");
    eventBus.$off("check-local-storage");

    this.firebaseInstance.off("value", (data) => {
      console.log("THE FIREBASE DATABASE EVENT IS OFF", data);
    });
  },

  components: {
    watchlistCarousel: () =>
      import(
        /* webpackChunkName: "watchlistCarousel" */ "@/components/watchlist/watchlistCarousel.vue"
      ),
    categoriesPlaceholder: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"
      ),
    watchlistGridCard: () =>
      import(/* webpackChunkName: "itemListTemp" */ "./watchlistGridCards.vue"),
    ErrorAlert: () =>
      import(
        /* webpackChunkName: "erroralert" */ "@/components/Templates/ErrorAlert.vue"
      ),
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.empty-message {
  // background-color: #1a1a1a;
  text-align: center;
  padding: 1rem;
  font-size: 14px;
  font-weight: 500;
  color: white;
  // height: 100vh;
  font-family: $font-regular;

  &.first-message {
    padding-top: 5rem;
  }
}

.heading-watchlist {
  color: $clr-light-gd3;
  font-family: $font-regular;
}

.watchlist {
  position: relative;
  .title {
    background: linear-gradient(90deg, #1d1d1d 0%, #1a1a1a 100%);
    // padding: 1rem 0rem 0rem;
    .py-0 {
      // padding: 0.6rem 1.2rem;
      display: flex;
     .iconTextWrapper{
       display: flex;
        img {
        cursor: pointer;
        width: 1rem;
        opacity: 0.5;
        // &:hover {
        //   opacity: 1;
        // }
      }
      .rotate-img {
        transform: rotate(180deg);
      }
      p {
        opacity: 1;
        color: #848486;
        font-family: $font-regular;
        font-size: 1rem;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 1.5rem;
      }
      &:hover{
        img{
          opacity: 1;
        }
        p{
          color: $clr-light-gd3;
        }
      }
     }
    }
  }
  .watchlist-grids {
    margin-top: 10px;
  }
  // .contents {
  //   // background-color: rgba(0, 0, 0, 0.8);
  //   // padding: 225px 0px;
  //   // height: 100vh;
  // }
  .big-margin {
    padding-bottom: 21rem;
  }
  .small-margin {
    padding-bottom: 1rem;
  }
  .error-content {
    color: #efeff4;
    font-family: $font-regular;
    font-size: 20px;
    font-weight: bold;
    height: 100vh;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    padding-top: 5rem;
  }
}
</style>
